/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import validUrl from 'valid-url';

/**
 * Internal dependencies.
 */
import LinkWithArrow from '../link-with-arrow';
import { getInternalSlug, leadingSlashIt, sendContactClickEvent } from '../../utils/functions';

/**
 * Link Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Link = (props) => {

	const {
		children,
		url,
		className,
		target,
		title,
		withArrow = false,
		color = '#000',
		external,
		tagParent,
		tagName
	} = props;

	const isExternal = target === '_blank';

	const handleGatsbyLinkClick = () => {
		if (!tagName || !tagParent) {
			return;
		}

		sendContactClickEvent(tagName, tagParent);
	}

	if (isExternal || external) {
		return withArrow ? (
			<LinkWithArrow className={className} text={title} link={url} color={color} external={true} />
		) : (
			<a className={className} target="_blank" rel="noopener noreferrer" href={url}>
				{children || title || ''}
			</a>
		);
	}

	const slug = validUrl.isWebUri(url) ? getInternalSlug(url) : leadingSlashIt(url);

	return withArrow ? (
		<LinkWithArrow className={className} text={title} link={slug} color={color} />
	) : (
		<GatsbyLink onClick={handleGatsbyLinkClick} className={className} to={slug}>
			{children || title || ''}
		</GatsbyLink>
	);
};

Link.propTypes = {
	className: PropTypes.string,
	target: PropTypes.string,
	withArrow: PropTypes.bool,
	tagParent: PropTypes.string,
	tagName: PropTypes.string,
};

Link.defaultProps = {
	tagParent: '',
	tagName: ''
};

export default Link;
