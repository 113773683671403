/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Link from '../../link';
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Feed Banner Component.
 *
 * @param {Object} page Page props.
 *
 * @return {jsx}
 */
const FeedBanner = ( { page, ctaLink, tagParent, tagName } ) => {

	const { title, excerpt } = page;

	return (
		<section className="feed-sections feed-banner">
			<div className="row">
				<div className="small-12 columns">
					<h1 className="feed-banner__title" dangerouslySetInnerHTML={ { __html: sanitize( title ) } } />
				</div>
				<div className="large-8 medium-12 small-11 columns">
					<div className="feed-banner__excerpt" dangerouslySetInnerHTML={ { __html: sanitize( excerpt ) } } />
				</div>
			</div>
			<div className="row">
				<div className="large-4 medium-4 small-12 columns">
					<Link { ...ctaLink } tagParent={ tagParent } tagName={ tagName } className="conversation-content__cta" />
				</div>
			</div>
		</section>
	);

};

FeedBanner.propTypes = {
	page: PropTypes.object,
	ctaLink: PropTypes.object,
	tagParent: PropTypes.string,
	tagName: PropTypes.string,
};

FeedBanner.defaultProps = {
	page: {
		title: '',
		excerpt: '',
		ctaLink: 'contact-us'
	},
	ctaLink: {
		title: '',
		url: '',
		target: ''
	},
	tagParent: '',
	tagName: '',
};

export default FeedBanner;
