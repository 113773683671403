/**
 * External dependencies.
 */
import React from 'react';
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import Link from './../../link';
import ArrowBigRight from './../../icons/long-arrow-right';
import classnames from 'classnames';

import './style.scss';

/**
 * CTABox component.
 *
 * @param {Object} link Link details.
 */
const CTABox = ( { link, theme = "light" } ) => {

	if ( isEmpty( link ) || isEmpty( link.title ) || isEmpty( link.url ) ) {
		return<></>;
	}

	const ctaBoxClass = classnames(
		'cta__box',
		{
			'cta__box-white': ( 'dark' === theme )
		}
	);

	let arrowColor = "#000";

	if ( 'dark' === theme ) {
		arrowColor = "#fff";
	}

	return(
		<Link className={ ctaBoxClass } external={ false } { ...link } >
			<h6 className="cta__title">
				{ link.title }
			</h6>
			<div className="cta__link">
				<span className="cta__link-text">{ ArrowBigRight( arrowColor ) }</span>
			</div>
		</Link>
	);

};

export default CTABox;
