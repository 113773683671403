/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Component to show title and description.
 *
 * @param {Object} param Component props.
 *
 * @return {String}
 */
const TitleDescription = ( { heading, description } ) => {

	return (
		<>
			<div className="large-1 medium-order-3 small-12 columns show-for-large">
				<hr className="feed-list__separator" />
			</div>
			<div className="feed-list__item-right large-5 medium-6 small-12 small-order-1 medium-order-3 columns">
				<div className="feed-list__inner">
					{ heading ? (
						<>
							<hr className="feed-list__separator hide-for-large" />
							<h3 className="feed-list__heading" dangerouslySetInnerHTML={ { __html: sanitize( heading ) } } />
						</>
					) : null }
					{ description ? (
						<div className="feed-list__description" dangerouslySetInnerHTML={ { __html: sanitize( description ) } } />
					) : null }
				</div>
			</div>
		</>
	);
};

export default TitleDescription;
