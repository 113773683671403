/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Feed Form Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const FeedForm = ( { content } ) => {

	if ( ! content ) {
		return;
	}

	return (
		<section className="feed-sections">
			<div className="row align-center feed-form__content-wrap">
				<div className="large-8 medium-10 small-12 column">
					<div className="feed-form__content" dangerouslySetInnerHTML={ { __html: sanitize( content ) } } />
				</div>
			</div>
		</section>
	);

};

FeedForm.propTypes = {
	content: PropTypes.string,
};

FeedForm.defaultProps = {
	content: '',
}

export default FeedForm;
