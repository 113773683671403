/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Feed Arrow List Component.
 *
 * @param {Object} meta Page props.
 *
 * @return {jsx}
 */
const FeedArrowList = ( { meta } ) => {

	const { headline, contentList } = meta;

	return (
		<section className="feed-sections feed-arrow-list">
			<div className="row feed-arrow-list__row">
				<div className="medium-12 small-10 columns">
					<h2 className="feed-arrow-list__headline" dangerouslySetInnerHTML={ { __html: sanitize( headline ) } } />
				</div>
				<div className="small-12 columns">
					<div className="row feed-arrow-list__lists">
						{ contentList.map( ( data, index ) => (
							<div className="large-4 medium-10 small-10 columns" key={ `list-${ index }` }>
								<div className="feed-arrow-list__list" dangerouslySetInnerHTML={ { __html: sanitize( data.listItem ) } } />
							</div>
						) ) }
					</div>
				</div>
			</div>
		</section>
	);

};

FeedArrowList.propTypes = {
	meta: PropTypes.object,
};

FeedArrowList.defaultProps = {
	meta: {
		headline: '',
		contentList: [
			{
				listItem: ''
			},
		]
	}
};

export default FeedArrowList;
