/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import FeedBanner from '../../components/feed/banner';
import FeedForm from '../../components/feed/form';
import FeedList from '../../components/feed/list';
import FeedArrowList from '../../components/feed/arrow-list';
import ConversationAdvertForm from '../../components/conversation-based-advertising/form';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import FooterCTA from './../../components/footer-cta';

import "./style.scss";

/**
 * Feed Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Feed = (props) => {

	const {
		pageContext: {
			page,
			page: {
				uri,
				seo,
				feedPageMeta: { featureList, arrowList, formEmbed },
				contactUsFormMeta: { contactForm, ctaLink },
				footerCtaLinks: { footerCtaWrapper },
			},
		}
	} = props;
	const openGraphImage = getOgImage(seo);

	return (
		<Layout title="Feed" uri={uri} className="feed-page">
			<SEO
				title={page.title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			{/* <FeedBanner page={page} ctaLink={ctaLink} tagParent="PUBLISHERS" tagName="CF_Contact" /> */}
			<FeedList meta={featureList} />
			<FeedArrowList meta={arrowList} />
			<FeedForm content={formEmbed.content} />
			{footerCtaWrapper && <section className="feed-cta">
				<FooterCTA links={footerCtaWrapper} />
			</section>}
			<ConversationAdvertForm meta={contactForm} tagParent="PUBLISHERS" tagName="CF_Submit" />
		</Layout>
	);
};

Feed.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Feed;
