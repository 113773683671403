/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Image from '../../image';
import { useDevice } from '../../../hooks';
import TitleDescription from './title-description';

import './style.scss';

/**
 * Feed List Component.
 *
 * @param {Array} meta Meta.
 *
 * @return {jsx}
 */
const FeedList = ( { meta } ) => {

	const device = useDevice();
	const isDesktop = device === 'desktop';
	const loopCount = meta.length - 1;

	return (
		<section className="feed-sections feed-list">
			{ meta.map( ( data, index ) => {

				const { imageSet1, imageSet2, heading, description } = data;
				const posterImage1 = ! isDesktop ? imageSet1.mobileImage : imageSet1.desktopImage;
				const posterImage2 = ! isDesktop ? imageSet2.mobileImage : imageSet2.desktopImage;

				const video1El = (
					<>
						{ posterImage1 ? (
							<Image { ...posterImage1 } />
						) : null }
					</>
				);

				const video2El = (
					<>
						{ posterImage2 ? (
							<Image { ...posterImage2 } />
						) : null }
					</>
				);

				let orderClass = '';

				if ( loopCount === index ) {
					orderClass = 'show-for-small-only';
				}

				return (
					<div className={ `row feed-list__item item-${ index + 1 }` } key={ `${ heading }-${ index }` }>
						{ imageSet1 ? (
							<>
								<div className="feed-list__item-left large-4 medium-6 small-12 columns hide-for-small-only">
									{ video1El }
									{ loopCount === index && video2El }
								</div>

								{
									loopCount === index ?
									<TitleDescription heading={ heading } description={ description } /> :
									<div className="feed-list__item-right large-8 medium-6 small-12 columns hide-for-small-only"> </div>
								}
							</>
						) : null }

						<div className={`feed-list__item-left large-4 medium-6 small-12 small-order-2 medium-order-1 columns ${ orderClass }`}>
							<figure className="feed-feature__figure">
								<div className="show-for-small-only">
									{ video1El }
								</div>
								{ video2El }
							</figure>
						</div>

						{ loopCount !== index &&
							<TitleDescription heading={ heading } description={ description } />
						}
					</div>
				);
			} ) }
		</section>
	);

};

FeedList.propTypes = {
	meta: PropTypes.array,
};

FeedList.defaultProps = {
	meta: [
		{
			heading: '',
			description: '',
			imageSet1: {
				mobileImage: '',
				desktopImage: '',
			},
			imageSet2: {
				mobileImage: '',
				desktopImage: '',
			}
		}
	]
};

export default FeedList;
