/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import CTABox from './cta-box';

import './style.scss';

/**
 * FooterCTA component.
 *
 * @param {Object} links Lists data.
 */
const FooterCTA = ( { links, theme = "light" } ) => {

	if ( isEmpty( links ) ) {
		return <></>;
	}

	return(
		<div className="row footer-cta-list">
			{
				links.map( ( link, index ) => {
					return(
						<div className="small-12 medium-6 columns footer-cta-list__box" key={`footer-cta-link-${index}`}>
							<CTABox link={ link.footerCtaLink } theme={ theme } />
						</div>
					);
				} )
			}
		</div>
	);

};

FooterCTA.propTypes = {
	links: PropTypes.array.isRequired
};

export default FooterCTA;
